/* global google */
import $ from 'jquery'
import 'slick-carousel'
import './vendor/gijgo/modular/js/core'
import './vendor/gijgo/modular/js/datepicker'

$.expr[':'].icontains = function (a, i, m) {
  return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) > -1
}

window.setCookie = function (name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
window.getCookie = function (name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}


$(function () {
  // generate anchor list
  if ($('.anchor-list').length > 0) {
    $('h2').each(function () {
      $('.anchor-list').append('<a class="anchor-link">' + $(this).text() + '</a>')
    })
  }
  // check if mobile device
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    $('body').addClass('mobile')
  }
  // toggle courses
  $('.course-item__title').on('click', function () {
    $(this).parent().siblings().slideToggle('fast', function () {})
    $(this).find('.button-round').toggleClass('open close')
  })
  // toggle filter
  $('.button-round--filter').on('click', function () {
    $('.course-filter').slideToggle('fast', function () {})
    $('.button-round--filter').toggleClass('open close')
  })
  // empty all filters
  $('.course-filter__empty, button-black--empty').on('click', function () {
    $.each($('input[type="checkbox"]:checked'), function () {
      $(this).prop('checked', false)
    })
  })
  // if more than 4 checkboxes per list, show toggle
  $('.checkbox-list').each(function () {
    if ($(this).children().length <= 4) {
      $(this).next('.show-more').hide()
    }
  })
  // show more
  $('.show-more').on('click', function () {
    if ($(this).hasClass('show-less')) {
      $(this).removeClass('show-less')
      $(this).children('span').text('Meer')
      $(this).prev('.checkbox-list').children(':gt(3)').slideUp()
    } else {
      $(this).addClass('show-less')
      $(this).children('span').text('Minder')
      $(this).prev('.checkbox-list').children(':gt(3)').slideDown()
    }
  })
  // homepage lists
  var listHeights = []
  $('.content__slide-panel').each(function () {
    var tempHeight = 0
    $('.content-list', this).children(':lt(4)').each(function () {
      tempHeight += $(this).height()
    })
    listHeights.push(tempHeight)
  })
  var colHeight = Math.min.apply(Math, listHeights)
  $('.content__slide-panel .content-list').each(function () {
    $(this).height(colHeight)
  })
  // show more list-items
  $('.panel-button').on('click', function () {
    if ($(this).hasClass('open')) {
      $(this).toggleClass('close open')
      var fullHeight = 0
      $(this).prev('.content-list').children().each(function () {
        fullHeight += $(this).height()
      })
      $(this).prev('.content-list').animate({height: fullHeight})
    } else {
      $(this).toggleClass('close open')
      $(this).prev('.content-list').animate({height: colHeight})
    }
  })
  // animate ripple on checkboxes
  $('.checkbox-ripple').default_checkboxRipple()
  // got to top animation
  $('#go-to-top').on('click', function () {
    $('html,body').animate({
      scrollTop: 0
    }, 'slow')
  })
  // show clear button when input has content
  $('.main-navigation__search input, .search-bar input').on('input', function () {
    if ($(this).val().length !== 0) {
      $('.clear-field').css('display', 'inline-block')
    }
  })
  $('.input textarea, .input input, .input select').each(function () {
    if (this.value !== '') {
      $(this).parent().addClass('not-empty')
    }
  })
  var inputs = document.querySelectorAll('textarea, input')
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('input', function () {
      if (this.value !== '') {
        this.parentNode.classList.add('not-empty')
      } else {
        this.parentNode.classList.remove('not-empty')
      }
      if (this.tagName === 'TEXTAREA') {
        this.style.height = 'auto'
        this.style.height = this.scrollHeight + 'px'
      }
    })
  }
  $('[date-type="date"]').datepicker({
    maxDate: new Date(),
    format: 'yyyy-dd-mm',
    change: function (e) {
      $(e.currentTarget).closest('.field').addClass('not-empty')
    }
  })
  var selects = document.querySelectorAll('select')
  for (i = 0; i < selects.length; i++) {
    selects[i].addEventListener('change', function () {
      if (this.value !== '') {
        this.parentNode.classList.add('not-empty')
      } else {
        this.parentNode.classList.remove('not-empty')
      }
    })
  }
  // clear input
  $('.clear-field').on('click', function () {
    $(this).prev('.input-search').val('')
  })
  // toggle menu
  $('.menu-toggle').on('click', function () {
    toggleMenu()
    $('body').addClass('noscroll')
    if ($('.main-navigation').hasClass('open')) {
      $('.main-navigation').addClass('nav')
      $('.main-navigation').removeClass('search')
      $('.main-navigation__wrapper').css('z-index', '9999')
      $('.menu-toggle i.material-icons').text('clear').css('color', '#ba0c2f')
    } else {
      $('.main-navigation').removeClass('nav')
      $('.main-navigation').removeClass('search')
      $('.main-navigation__wrapper').removeAttr('style')
      $('.menu-toggle i.material-icons').text('dehaze').removeAttr('style')
      $('.menu__magnifyingGlassIcon i.material-icons, .alt-header__magnifyingGlassIcon i.material-icons').text('search').removeAttr('style')
      $('body').removeClass('noscroll')
    }
  })
  $('.main-navigation__close').on('click', function () {
    toggleMenu()
    $('body').removeClass('noscroll')
    $('.main-navigation').removeClass('search').removeClass('nav')
    $('.menu-toggle i.material-icons').text('dehaze').removeAttr('style')
    $('.menu__magnifyingGlassIcon i.material-icons, .alt-header__magnifyingGlassIcon i.material-icons').text('search').removeAttr('style')
  })
  // open menu and focus search
  $('.alt-header__magnifyingGlassIcon, .menu__magnifyingGlassIcon').on('click', function () {
    toggleMenu()
    $('.main-navigation').toggleClass('search').removeClass('nav')
    $('.main-navigation__wrapper').css('z-index', '999')
    $('body').addClass('noscroll')
    $('#menu-search').focus()
    if ($('.main-navigation').hasClass('open')) {
      $('.main-navigation').removeClass('nav')
      $('.main-navigation').addClass('search')
      $('.main-navigation__wrapper').css('z-index', '9999')
      $('.menu__magnifyingGlassIcon i.material-icons, .alt-header__magnifyingGlassIcon i.material-icons').text('clear').css('color', '#ba0c2f')
    } else {
      $('.main-navigation').removeClass('nav')
      $('.main-navigation').removeClass('search')
      $('.main-navigation__wrapper').removeAttr('style')
      $('.menu__magnifyingGlassIcon i.material-icons, .alt-header__magnifyingGlassIcon i.material-icons').text('search').removeAttr('style')
      $('.menu-toggle i.material-icons').text('dehaze').removeAttr('style')
      $('body').removeClass('noscroll')
    }
  })
  // Gallery
  $('.gallery').slick({
    prevArrow: '<img class="gallery-left" src="/assets/img/gallery-left.svg" />',
    nextArrow: '<img class="gallery-right" src="/assets/img/gallery-right.svg" />'
  })
  $('.gallery-mobile').slick({
    prevArrow: '<img class="gallery-left" src="/assets/img/gallery-left.svg" />',
    nextArrow: '<img class="gallery-right" src="/assets/img/gallery-right.svg" />'
  }).resize()
  $('body:not(.mobile) .header-gallery').slick({
    dots: true,
    dotsClass: 'gallery-nav',
    prevArrow: '<img class="gallery-left" src="/assets/img/gallery-left-red.svg" />',
    nextArrow: '<img class="gallery-right" src="/assets/img/gallery-right-red.svg" />'
  })
  $('body.mobile .header-gallery').slick({
    dots: true,
    dotsClass: 'gallery-nav',
    arrows: false
  })
  // sticky tabs
  if ($('body').not('.mobile') && $('.tabs').length > 0) {
    $(window).scroll(function () {
      if ($(window).scrollTop() >= $('.tabs').offset().top) {
        $('.page-tabs').addClass('fixed')
      } else {
        $('.page-tabs').removeClass('fixed')
      }
    })
  }
  // sticky anchor links
  if ($('body').not('.mobile') && $('.anchor-list').length > 0) {
    $(window).scroll(function () {
      if ($(window).scrollTop() >= $('.anchors').offset().top) {
        $('.anchor-list').addClass('fixed')
      } else {
        $('.anchor-list').removeClass('fixed')
      }
    })
  }
  // scroll to anchor
  $('body').on('click', '.anchor-link', function () {
    var anchorTag = $('h2:contains(' + $(this).text() + ')')
    $('html, body').animate({scrollTop: anchorTag.offset().top - 80}, '500')
  })
  // radio button 'other'
  $('#radiol5').on('click', function () {
    $('#other').focus()
  })
  // tab toggle
  $('.page-tabs a').on('click', function (e) {
    e.preventDefault()
    $(this).siblings().removeClass('active')
    $(this).addClass('active')
    $(this).closest('.container').find('.tab').hide()
    $(this).closest('.container').find('.tab:nth(' + ($(this).index()) + ')').show()
  })
  if ($('#map').length > 0) {
    window.initMap()
  }
  // open overlay
  if ($('body').not('.mobile').length > 0) {
    $('.header-video').on('click', function () {
      $('#overlay').css('display', 'grid')
      $('body').addClass('noscroll')
    })
  } else {
    $('.header-video').on('click', function () {
      $('#overlay').css('display', 'block')
      $('body').addClass('noscroll')
    })
  }
  // overlay close buttons
  $('body').on('click', '.overlay-close-button', function () {
    $('#overlay').hide()
    $('body').removeClass('noscroll')
  })
})

function toggleMenu () {
  $('.main-navigation__wrapper, .main-navigation').toggleClass('closed').toggleClass('open')
}

$.fn.default_checkboxRipple = function () {
  var self, checkbox, ripple, size, eWidth, eHeight
  self = this
  checkbox = self.find('.input-checkbox')
  checkbox.on('mousedown', function (e) {
    if (e.button === 2) {
      return false
    }
    if ($(this).find('.ripple').length === 0) {
      $(this).append('<span class="ripple"></span>')
    }
    ripple = $(this).find('.ripple')
    eWidth = $(this).outerWidth()
    eHeight = $(this).outerHeight()
    size = Math.max(eWidth, eHeight)
    ripple.css({
      'width': size,
      'height': size
    })
    ripple.addClass('animated')

    $(this).on('mouseup', function () {
      setTimeout(function () {
        ripple.removeClass('animated')
      }, 200)
    })
  })
}
// Filters etc
$(function () {
  // toggle faq
  $('.course-toolbar__search .input-search').on('input', function () {
    if (this.value === '') {
      $(this).closest('.tab').find('.course-item').show()
    } else {
      $(this).closest('.tab').find('.course-item:not(:icontains(' + this.value + '))').hide()
      $(this).closest('.tab').find('.course-item:icontains(' + this.value + ')').show()
    }
  })
  $('.course-row  .course-toolbar__sort select').on('change', function () {
    if (this.value !== $(this).attr('data-prev')) {
      $(this).closest('.tab').find('.course-toolbar').after($(this).closest('.tab').find('.course-item').get().reverse())
    }
    $(this).attr('data-prev', this.value)
  })
  $('.faq-item__title').on('click', function () {
    $(this).parent().siblings().slideToggle('fast', function () {})
    $(this).find('.button-round').toggleClass('open close')
  })
  // empty all filters
  $('.faq-filter__empty').on('click', function () {
    $.each($('input[type="checkbox"]:checked'), function () {
      $(this).prop('checked', false)
    })
  })
  $('.download-item').click(function () {
    $(this).find('.input-checkbox input').prop('checked', !$(this).find('.input-checkbox input').prop('checked'))
    $('.download-button').attr('data-selected', $('.download-item input:checked').length)
    if ($('.download-button').attr('data-selected') === '0') {
      $('.download-button').prop('disabled', true)
    } else {
      $('.download-button').prop('disabled', false)
    }
  })
})

$('.maps__box__contractButton').on('click', function () {
  $(this).closest('.maps__box').toggleClass('toggled')
  $(this).toggleClass('open close')
})

window.initMap = function () {
  var uluru = {
    lat: 51.601870,
    lng: 4.785315
  }
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 17,
    center: uluru,
    disableDefaultUI: true,
    zoomControl: true,
    scaleControl: true,
    rotateControl: true

  })
  var x = new google.maps.Marker({ // eslint-disable-line
    position: uluru,
    map: map
  })

  var centerControlDiv = document.createElement('div')

  centerControlDiv.index = 1
  map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv)
  var controlUI = document.createElement('div')
  controlUI.style.backgroundColor = '#fff'
  controlUI.style.border = '2px solid #fff'
  controlUI.style.borderRadius = '3px'
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
  controlUI.style.cursor = 'pointer'
  controlUI.style.marginBottom = '22px'
  controlUI.style.marginTop = '22px'
  controlUI.style.marginRight = '22px'
  controlUI.style.marginBottom = '22px'
  controlUI.style.textAlign = 'center'
  controlUI.title = 'Toon volledige kaart'
  centerControlDiv.appendChild(controlUI)

  // Set CSS for the control interior.
  var controlText = document.createElement('div')
  controlText.style.color = 'rgb(25,25,25)'
  controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
  controlText.style.fontSize = '14px'
  controlText.style.lineHeight = '28px'
  controlText.style.paddingLeft = '5px'
  controlText.style.paddingRight = '5px'
  controlText.innerHTML = 'Toon volledige kaart'
  controlUI.appendChild(controlText)
  controlUI.addEventListener('click', function () {
    window.open('https://www.google.nl/maps/@' + map.getCenter().toUrlValue() + ',' + map.getZoom() + 'z')
  })
}

window.onload = function () {

  if(document.querySelector('.om-cookie__change-cookies')) {
      document.querySelector('.om-cookie__change-cookies').addEventListener('click', function () {
          document.querySelector('.om-cookie__settings').classList.add('om-cookie__settings--open');
      });
  }

  var cookieValue = window.getCookie('EuCookieBanner');

  if (document.querySelector('.om-cookie')) {
    var sc = document.getElementById('social-cookies');
    var ac = document.getElementById('analytics-cookies');
    var mc = document.getElementById('marketing-cookies');
    var save =  document.querySelector('.om-cookie__save-cookie');

    if(cookieValue) {
        var has =  function (a, b) {
            return a.indexOf(b) !== -1;
        }
        
        sc.checked = has(cookieValue, 'social') || has(cookieValue, 'yes');
        ac.checked = has(cookieValue, 'analytics') || has(cookieValue, 'yes');
        mc.checked = has(cookieValue, 'marketing') || has(cookieValue, 'yes');
    }

    save.addEventListener('click', function (e) {
        if(document.querySelector('.om-cookie__snackbar').classList.contains('om-cookie__snackbar--show')) {
            var elem = document.querySelector('.om-cookie__snackbar');
            var newElem = elem.cloneNode(true);
            elem.parentNode.replaceChild(newElem, elem);
            document.querySelector('.om-cookie__snackbar').classList.add('om-cookie__snackbar--show');
        } else {
            document.querySelector('.om-cookie__snackbar').classList.add('om-cookie__snackbar--show');
        }
        
        if(sc.checked && ac.checked && mc.checked) {
            window.setCookie('EuCookieBanner', 'yes', 365);
            location.reload();
            return;
        }
        
        var cookieString = [];
        if(sc.checked) {
            cookieString.push('social');
        }
        if(ac.checked) {
            cookieString.push('analytics');
        }
        if(mc.checked) {
            cookieString.push('marketing');
        }
        if(cookieString.length === 0) {
            window.setCookie('EuCookieBanner', 'none', 365);
            setTimeout(function () {
                document.querySelector('.om-cookie__settings').classList.remove('om-cookie__settings--open');
            }, 2000);
            location.reload(true);
            return;
        }
        window.setCookie('EuCookieBanner', cookieString.join('-'), 365);

        setTimeout(function () {
            document.querySelector('.om-cookie__settings').classList.remove('om-cookie__settings--open');
            location.reload(true);
        }, 2000);
    });
  }

  $('.newsletter-form__form, #form').submit(function(ev) {
    if (document.querySelector('#privacy').checked) {
      $('.privacy-error').hide();
      $('#privacy').next().removeClass('val-error');
      this.submit();
    } else {
      ev.preventDefault();
      $('.privacy-error').show();
      $('#privacy').next().addClass('val-error');
    }
  });
};
